import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { ButtonSecondary } from "../Buttons/Buttons"

import UniversityCard from "./UniversityCard"
import GetInTouchPinkBox from "../GetInTouchPinkBox"
import { Container } from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import _ from "lodash"

interface IUniversityCard {
  img?: string
  name: string
  desc: string
  UniversityInfo: Array<IInfo>
}
interface IInfo {
  isInUk: boolean
}
interface UniversityFlierProps {
  data: Array<IUniversityCard>
}

// type IUniversities = Array<IUniversityCard>

// Sorry!
type IUniversities = any

const UniversitiesFiler: FunctionComponent<UniversityFlierProps> = ({ data }: IUniversities) => {
  const shuffledUnis = _.cloneDeep(data).sort(() => Math.random() - 0.5)
  const [universities, setUniversities] = useState(shuffledUnis.slice(0, 6))
  const [sortType, setSortType] = useState("name")

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const newUnis = _.cloneDeep(data).sort(() => Math.random() - 0.5)
      switch (sortType) {
        case "name":
          setUniversities(_.cloneDeep(newUnis).slice(0, 6))
          break
        case "uk":
          setUniversities(
            _.cloneDeep(newUnis)
              .filter(uni => uni.UniversityInfo.isInUk)
              .slice(0, 6)
          )
          break

        case "international":
          setUniversities(
            _.cloneDeep(newUnis)
              .filter(uni => uni.UniversityInfo.isInUk != true)
              .slice(0, 6)
          )
          break
      }
    }
    return () => {
      isMounted = false
    }
  }, [sortType])

  return (
    <FullWidth>
      <Subtitle>OUR MEMBERS</Subtitle>
      <Title>OUR LEADING FASHION UNIVERSITIES</Title>

      <Container maxWidth={"lg"}>
        <ButtonContainer>
          <input
            type={"radio"}
            id={"region-radio-"}
            name={"region"}
            value={"name"}
            checked={"name" === sortType}
            onChange={e => setSortType("name")}
          />
          <label htmlFor={"region-radio-"}>All</label>

          <input
            type={"radio"}
            id={"region-radio-2"}
            name={"region"}
            value={"uk"}
            checked={"uk" === sortType}
            onChange={e => setSortType("uk")}
          />
          <label htmlFor={"region-radio-2"}>UK</label>

          <input
            type={"radio"}
            id={"region-radio-3"}
            name={"region"}
            value={"international"}
            checked={"international" === sortType}
            onChange={e => setSortType("international")}
          />
          <label htmlFor={"region-radio-3"}>International</label>
        </ButtonContainer>

        <UniversitiesFilerContainer>
          {universities.map((item, index) => {
            let img= null
            try{
              img = item.UniversityInfo.featuredimage.localFile.childImageSharp.gatsbyImageData
            }catch(e){
              // console.log("no img for " + item.title)
            }
            return (
              <UniversityCard
                key={"uni-card-" + uuidv4()}
                name={item.title}
                desc={item.UniversityInfo.universityDescription}
                slug={item.slug}
                img={img}
              />
            )
          })}
        </UniversitiesFilerContainer>

        <UniversitiesFilerContainerMobile>
          {/* <MobileFlier>
            <UniversityCarouselDrag data={universities} isHidden={false}/>
          </MobileFlier> */}
        </UniversitiesFilerContainerMobile>
        <ButtonContainer>
          <ButtonSecondary to="/universities">SHOW ALL UNIVERSITIES</ButtonSecondary>
        </ButtonContainer>
        <PinkBoxContainer>
          <GetInTouchPinkBox></GetInTouchPinkBox>
        </PinkBoxContainer>
      </Container>
    </FullWidth>
  )
}

export default UniversitiesFiler

const FullWidth = styled.div`
  /* border: 1px solid red; */
  width: 100vw;
  /* max-width: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 10;
  margin-bottom: 80px;
  background-color: #ecf9fd;
  padding-top: 100px;
  margin-top: 32px;

  margin-bottom: 250px;
  position: relative;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 10px;
    padding-top: 50px;
  }
`

const UniversitiesFilerContainer = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  width: 100%;
  /* max-width: 1440px; */
  margin-bottom: 50px;
  padding: 30px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`
const UniversitiesFilerContainerMobile = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`
const MobileFlier = styled.div``

const PinkBoxContainer = styled.div`
  position: relative;
  bottom: -250px;

  margin: 0 auto;
  margin-top: -200px;
  width: 100%;
  background-color: blue;
  /* @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: -340px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: -440px;
  } */
`

const Subtitle = styled.p`
  letter-spacing: 2px;
  @media (max-width: 768px) {
    font-size: 12px;

    line-height: 22px;
  }
`
const Title = styled.h3`
  letter-spacing: 2px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 9px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
  }
`

const Gap = styled.div`
  width: 8px;
`

import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import { EventsCarosel } from "../EventsCarosel/EventsCarosel"
import { Container } from "@material-ui/core"
interface CarouselDragProps {
  id: string
  events: any[]
}

const CatchEvents: FunctionComponent<CarouselDragProps> = ({ id, events }) => {
  const [active, setActive] = useState(false)
  const [video, setVideo] = useState({ title: "", url: "" })

  return (
    <Container maxWidth={"xl"}>
      <Title>CATCH UP ON OUR GRADUATE EVENTS</Title>
      <CatchEventRow>
        <CrossTitleContainer>
          <CrossSubtitle>EVENT HIGHLIGHTS</CrossSubtitle>
          <CrossTitle>GFW HIGHLIGHTS</CrossTitle>
        </CrossTitleContainer>

        {/* <CarouselEvent events={events} /> */}
        <CarouselContainer>
          <EventsCarosel date={""} setActive={setActive} setVideo={setVideo} events={events} />
        </CarouselContainer>
      </CatchEventRow>
    </Container>
  )
}

export default CatchEvents

const FullWidth = styled.div`
  padding-top: 30px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  position: relative;
`
const Title = styled.h3`
  margin-top: 30px;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 0.62px;
  line-height: 55px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 24px;

    letter-spacing: 0.3px;
    line-height: 29px;
  }
`

const CrossTitleContainer = styled.div`
  width: 35%;
  height: 500px;
  max-width: 500px;
  /* width: 500px!important;
  height: 500px; */
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMycHgiIGhlaWdodD0iMjMycHgiIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIzMi44NyAyMzIuODciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojMGRiMGU3O2ZpbGwtcnVsZTpldmVub2RkO308L3N0eWxlPjwvZGVmcz48cG9seWdvbiBpZD0iTWFzayIgY2xhc3M9ImNscy0xIiBwb2ludHM9IjE3OS40NiAxMTYuNDQgMjMyLjg3IDYzLjAzIDE2OS44NCAwIDExNi40NCA1My40MSA2My4wMyAwIDAgNjMuMDMgNTMuNDEgMTE2LjQ0IDAgMTY5Ljg0IDYzLjAzIDIzMi44NyAxMTYuNDQgMTc5LjQ2IDE2OS44NCAyMzIuODcgMjMyLjg3IDE2OS44NCAxNzkuNDYgMTE2LjQ0Ii8+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    background-position: center;
    width: 100%;
    height: 320px;
    margin-bottom: -30px;
    z-index: 0;
  }
`
const CarouselContainer = styled.div`
  width: 65%;
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const CrossSubtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 12px;

    line-height: 14px;
  }
`

const CrossTitle = styled.h3`
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 55px;
  max-width: 50%;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;

    line-height: 35px;
  }
`

const CatchEventRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`

import React from "react"
import styled from "styled-components"
import Cross from "../assets/svg/GFW_cross.svg"
import { ButtonSecondary, SizeType } from "./Buttons/Buttons"

const GetInTouchPinkBox: React.FC = () => {
  return (
    <FullWidth>
      <GetInTouchContainer>
        <PinkBoxHeading>
          <CrossLineBox>
            <Cross width="50px" height="50px" />
            <Line />
          </CrossLineBox>
          IF YOU WOULD LIKE MORE INFORMATION ON GFW PLEASE GET IN TOUCH
        </PinkBoxHeading>
        <PinkBoxContent>
          <p>
            Want to chat? Reach out to us and we'll be happy to answer your questions. We love partnering with other
            business and industry leaders too.
          </p>
          <div>
            <ButtonSecondaryPinkBox to="/get-in-touch" whiteborder={"true"} customwidth={"300px"} size={SizeType.large}>
              GET IN TOUCH
            </ButtonSecondaryPinkBox>
          </div>
        </PinkBoxContent>
      </GetInTouchContainer>
    </FullWidth>
  )
}

export default GetInTouchPinkBox

const FullWidth = styled.div`
  width: 100% !important;
  align-items: center;
`

const GetInTouchContainer = styled.div`
  display: flex !important;
  background-color: ${props => props.theme.colors.secondary};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  margin: 0;
  margin-bottom: 80px;
  padding: 43px 76px 100px 96px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    max-width: 100%;
    padding: 80px 20px;
  }
  @media (min-width: 768px) {
    padding: 43px 20px 100px 20px;
  }
`
const CrossLineBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 17px;
`
const PinkBoxHeading = styled.div`
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 37px;
  text-align: left;
  margin-right: 40px;
  margin-left: 40px;
  @media (min-width: 768px) {
    max-width: 360px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
`
const Line = styled.hr`
  margin-left: 15px;
  width: 210px;
  height: 4px;
  border: none;
  background-color: #fff;
  @media (max-width: 768px) {
    width: 150px;
  }
`

const PinkBoxContent = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-right: 160px;
  padding-top: 40px;
  & p {
    color: white;
  }
  @media (min-width: 768px) {
    max-width: 495px;
  }
  @media (max-width: 768px) {
    padding-top: 10px;
    margin-right: 0px;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
  }
`

const ButtonSecondaryPinkBox = styled(ButtonSecondary)`
  @media (max-width: 768px) {
    width: 100%;
  }
`

import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Card } from "@material-ui/core"

import { DateTime } from "luxon"

interface EventsCard {
  data: any
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  setVideo: React.Dispatch<
    React.SetStateAction<{
      title: string
      url: string
    }>
  >
}

const EventsCard: React.FC<EventsCard> = ({ data, setActive, setVideo }: EventsCard) => {
  const [shadow, setShadow] = useState(0)
  const onMouseOver = () => setShadow(15)
  const onMouseOut = () => setShadow(0)
  // 30/03/2021 12:00 am
  const date = DateTime.fromFormat(data.events.timeOfEvent, "dd/MM/yy t")

  const diff = date.diff(DateTime.now(), ["days"])

  return (
    <UniversityCardContainer
      onClick={() => {
        setActive(true)
        setVideo({ title: data.title, url: data.events.vimeoUrl })
      }}
    >
      <StyledCard
        elevation={shadow}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        //Converting this boolean into a string to stop the browser complaining. I think its a styled component vs material UI issue.
        upcoming={(diff.days > 0).toString()}
      >
        <ImgContainer className="gatsby-image-wrapper">
          <DarkBackgorund />
          <GatsbyImage
            image={data.events.featuredImage.localFile.childImageSharp.gatsbyImageData}
            style={{ height: 400, width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            //placeholderStyle={{ opacity: 0 }}
            loading={"eager"}
            alt={data.title}
          />
        </ImgContainer>
        <PortfolioInfoBox>
          {diff.days > 0 ? <Tag upcoming={diff.days > 0}>Upcoming Event</Tag> : <Tag>Previous Event</Tag>}
          <PortfolioName>{data.title}</PortfolioName>
          <PortfolioDesc>{date.toLocaleString({ day: "numeric", month: "long", year: "numeric" })}</PortfolioDesc>
        </PortfolioInfoBox>
      </StyledCard>
    </UniversityCardContainer>
  )
}
export default EventsCard

const DarkBackgorund = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  z-index: 2;
`
const UniversityCardContainer = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  max-width: 315px;
  max-height: 400px;
  &:hover {
    text-decoration: none;
  }
`
const ImgContainer = styled.div`
  display: flex;
  min-height: 218px;
  background-color: white;
  align-items: flex-start;
  justify-content: center;
  z-index: 10;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    min-height: 155px;

    border-top: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
  }
`

interface StyledCardInterface {
  upcoming: string
}

const StyledCard = styled(Card)<StyledCardInterface>`
  border-top: 3px solid ${props => (props.upcoming === "true" ? "#FBE600" : "black")};
  border-radius: 0px !important;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* border: 1px solid red; */
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    border: 1px solid #e3e3e3;
  }
`

const PortfolioCardContainer = styled(Link)`
  margin: 0 10px;
  background-color: #ffffff;

  &:hover {
    text-decoration: none;
  }

  max-width: 315px;
`

const PortfolioInfoBox = styled.div`
  padding: 21px 27px 26px;
  background: white;
  height: 185px;
  width: 295px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 5px 0 rgba(123, 123, 123, 0.2);
  transition: transform 1s ease;
  margin-top: -190px;
  margin-right: 0;
  z-index: 16;
  @media (max-width: 768px) {
    padding: 19px 23px;
  }
`

const PortfolioName = styled.p`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  height: 70px;
  margin-bottom: 18px;
  color: black;
  @media (max-width: 768px) {
    font-size: 16px;

    line-height: 21px;
  }
`

const PortfolioDesc = styled.p`
  color: #4a4a4a !important;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: 768px) {
    font-size: 14px;

    line-height: 24px;
  }
`
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-right: 0%;
  }
`

interface TagInterface {
  upcoming: boolean
}

const Tag = styled.b<TagInterface>`
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => (props.upcoming ? "#FBE600" : "black")};
  color: ${props => (props.upcoming ? "black" : "white")};
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 5px 5px 0;
  height: 20px;
  width: auto;
  padding: 0px 8px 0px;
  border-radius: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10px;
  }
`

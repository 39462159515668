import React, { useEffect } from "react"
import UniversitiesFiler from "../components/Home/UniversitiesFilter"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import GridHomeHeader from "../components/Home/GridHomeHeader"
import { InstagramFeed } from "../components/InstagramFeed"
import CatchEvents from "../components/Home/CatchEvents"

const headings = ["Sustainability", "Diversity", "Heritage", "Award Nominees"]

const Home: React.FC = ({ data }) => {
  const unis = data.allWpUniversity.edges.map(v => v.node)
  const portfolios = data.allWpPortfolio.edges.map(v => v.node)
  // console.log(data)
  // console.log("Home: " + data.wpPage.id)
  // console.log("Events Home: " + data.EventsContent.id)
  // console.log("Showing Event Homepage: " + data.controls.event_page_controls.toggleEventHompage)

  let home = data.wpPage
  if(data.controls.event_page_controls.toggleEventHompage){
    home = data.EventsContent
  }
 
  const blocks = LazyBlockFactory(home.lazy_data, "Home")
  const events = data.allWpEvent.edges.map(v => v.node)

  useEffect(() => {
    const eventControlPageUrl =  "https://cms.gfw-dev.co.uk/wp-json/wp/v2/pages?slug=event-page-controls"

    fetch(eventControlPageUrl)
    .then(response => response.json())
    .then(data => console.log(data));
  })

  // console.log(home)
  
  return (
    <Layout title={"Graduate Fashion Week - Home"}>
      <GridHomeHeader title={home.HomeData.headingTitle} body={home.HomeData.headingDescription} 
      
      address={home.HomeData.address}
      bottomRowHidden={home.HomeData.bottomRowHidden}
      cta1IsHidden={home.HomeData.cta1IsHidden}
      cta1Label={home.HomeData.cta1Label}
      cta1Url={home.HomeData.cta1Url}
      cta2IsHidden={home.HomeData.cta2IsHidden}
      cta2Label={home.HomeData.cta2Label}
      cta2Url={home.HomeData.cta2Url}
      endDate={home.HomeData.endDate}
     
      startDate={home.HomeData.startDate}
      image={home.HomeData.homepageHeaderImage}
      
      />
      
      {blocks.map((v, i) => {
        if (i == 1) {
          return (
            <div key={"uni-home-filter"}>
              <UniversitiesFiler data={unis} />
              {v}
            </div>
          )
        } else {
          return v
        }
      })}
      {/* {data.site.siteMetadata.amplifyBranch !== "master" ? (
        <> */}
          

          <CatchEvents events={events} />
        {/* </>
      ) : ( 
        <></>
      )}  */}

      <InstagramFeed />
    </Layout>
  )
}

export default Home
export const pageQuery = graphql`
  query home {
    site {
      siteMetadata {
        amplifyBranch
      }
    }

    allWpUniversity {
      edges {
        node {
          id
          slug
          UniversityInfo {
            featuredimage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
            isInUk
            universityDescription
            universityLocationCityForSearch
          }
          title
        }
      }
    }
    wpPage(title: { eq: "Home" }) {
      id
      HomeData {
        address
        bottomRowHidden
        cta1IsHidden
        cta1Label
        cta1Url
        cta2IsHidden
        cta2Label
        cta2Url
        endDate
        fieldGroupName
        getInTouchFooterBody
        getInTouchFooterCat
        getInTouchFooterHeading
        headingDescription
        headingTitle
        startDate
        homepageHeaderImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      }
      lazy_data
    }
   controls: wpPage(title: {eq: "Event Page Controls"}) {
    id
    event_page_controls {
      toggleEventHompage
    }
  }
    
    allWpPortfolio {
      edges {
        node {
          id
          title
          slug
          student_portfolio {
            aboutMeHeading
          }
          student_profile {
            studentName
            specialism
            searchImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpEvent {
      edges {
        node {
          id
          title
          events {
            vimeoUrl
            timeOfEvent
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    allWpKeynoteSpeaker {
      edges {
        node {
          keynoteSpeakers {
            description
            name
            role
            date
            featuredImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    EventsContent: wpPage(title: { eq: "Home Event" }) {
      id
      title
      HomeData {
        fieldGroupName
        getInTouchFooterBody
        getInTouchFooterCat
        headingTitle
        headingDescription
        getInTouchFooterHeading
      }
      lazy_data
    }
  }
`

import React, { useLayoutEffect, useState, FunctionComponent } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import EventsCard from "./EventsCard"
import { Container } from "@material-ui/core"
import { Lightbox } from "../Lightbox/Lightbox"

interface ICarouselItems {
  name: string
}

interface EventsCarouselDragProps {
  date?: string
  events: any
  title: string
  url: string
}

export const EventsCarosel: FunctionComponent<EventsCarouselDragProps> = ({ date, events }) => {
  const [slides, setSlides] = useState(4)
  const [padding, setPadding] = useState(20)
  const [charLimit, setCharLimit] = React.useState(140)

  const [active, setActive] = useState(false)
  const [video, setVideo] = useState({ title: "", url: "" })

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + "..."
  }
  useLayoutEffect(() => {
    const hideArrows = () => {
      if (typeof window !== "undefined") {
        const nextBtn = document.querySelector(".swiper-button-next")
        const prevBtn = document.querySelector(".swiper-button-prev")
        if (window.innerWidth >= 1200 && data.length <= 3) {
          nextBtn.style.display = "none"
          prevBtn.style.display = "none"
        } else if (window.innerWidth < 1200 && data.length > 3) {
          nextBtn.style.display = "inline-block"
          prevBtn.style.display = "inline-block"
        }
      }
    }

    function updateSize() {
      if (typeof window !== "undefined") {
        if (window.innerWidth <= 767) {
          setSlides(window.innerWidth / (350 + padding * 3))
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          setSlides(window.innerWidth / (350 + padding * 6.5))
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
          setSlides(window.innerWidth / (350 + padding * 7))
        } else {
          setSlides(2.8)
        }
      }
    }
    window.addEventListener("resize", updateSize)
    // window.addEventListener('resize', hideArrows)
    updateSize()
    // hideArrows()
    return () => {
      window.removeEventListener("resize", updateSize)
      // window.removeEventListener('resize', hideArrows)
    }
  }, [])

  return (
    <>
      <Lightbox active={active} setActive={setActive}>
        <iframe
          src={active ? video.url : null}
          title={video.title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          width="100%"
          height="100%"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Lightbox>
      <ContainerS>
        {date && <LetterHeading>{date}</LetterHeading>}

        <SwiperContainer>
          <Swiper
            className="swiper-container"
            slidesPerView={"auto"}
            navigation
            scrollbar={{
              hide: false,
            }}
          >
            <SwipperWrapper id={"swiper-" + date} className="swiper-wrapper">
              {events.map((item, i) => {
                return (
                  <SwiperSlide key={"events-carosel-item-" + i} className="carousel-drag-slide">
                    {/* <UniversityCardCarousel
                    img={
                      item.UniversityInfo.featuredimage.localFile
                        .childImageSharp.gatsbyImageData
                    }
                    name={item.title}
                    desc={item.UniversityInfo.universityDescription}
                    slug={item.slug}
                  /> */}
                    <EventsCard data={item} setActive={setActive} setVideo={setVideo} />
                  </SwiperSlide>
                )
              })}
            </SwipperWrapper>
          </Swiper>
        </SwiperContainer>
      </ContainerS>
    </>
  )
}

const ContainerS = styled(Container)`
  padding-bottom: 30px;
  z-index: 0;
  overflow: hidden;
  /* padding: 20px 20px 0px;
    border: 1px solid red;

  margin: 0 auto;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0px 20px;
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    padding: 55px 3% 0 3%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0;
  } */
  .swiper-container {
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;
    overflow: visible !important;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .swiper {
  }
  .carousel-drag-slide {
    cursor: grab;
    height: auto;
    margin-right: 10px;
    width: 315px !important;
    min-width: 315px !important;
    padding: 0px 0px 48px;
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 315px !important;
      min-width: 315px !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &:hover .image__container {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
    }
    &:hover .text__container {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: calc(100vw - 140px);
    background: ${props => props.theme.colors.turquoise};
    left: 100px;
    bottom: 10px;

    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${props => props.theme.colors.turquoise};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 0px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny44NCAxMS4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZGIwZTc7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgY2xhc3M9ImNscy0xIiBkPSJNNDUuMzgsNiw0MS4xNiwxLjg0bDEtMSw1LjcsNS42Mi01LjcsNS42Mi0xLTFMNDUuMzgsN0gwVjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjg4KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    /* background-color: ${props => props.theme.colors.turquoise}; */
    background-size: 100% auto;
    background-position: center;
    width: 45px;
    height: 21px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    /* background: url(../assets/svg/long-arrow-right.svg); */
    background-repeat: no-repeat;
    background-size: 100% auto;

    background-position: center;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`

const SwiperContainer = styled(Container)`
  /* margin: 0 auto; */
  /* position: relative; */
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  box-sizing: content-box;
`

interface ILinkWrapper {
  noMarginTop?: boolean
}

const LetterHeading = styled.h4`
  color: black;
  /* width: 100%; */
  text-align: left;
  align-items: top;

  font-size: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2rem;
    height: 70px;
    margin-top: 10px;
    margin-bottom: -10px;
  }
`

import React from "react"
import "./Lightbox.scss"

interface LightBoxInterface {
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const Lightbox: React.FC<LightBoxInterface> = ({ active, children, setActive }) => {
  return (
    <div className={`lightbox ${active && "active"} `} onClick={() => setActive(false)}>
      <div className="inner">
        <div className="content" onClick={e => e.stopPropagation()}>
          <div style={{ textAlign: "right", width: "110%", position: "relative" }}>
            {/* <button className="close-button" aria-label="Close" onClick={() => setActive(false)}>
              <Close fill="white" stroke="white" />
            </button> */}
          </div>
          {children}
          {/* <div style={{ textAlign: "right",  width: "100%", height: "30%", paddingRight: "0px" }}>
                  
                </div>   */}
        </div>
      </div>
    </div>
  )
}
